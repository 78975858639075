import axios from 'axios'
import React, {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {URL} from '../../redux/common/url'
import {toast} from 'react-toastify'

const ViewDetails: FC = () => {
  const [reportData, setReportData] = useState<any>({})
  console.log(reportData)

  const {id, status} = useParams()

  const getReports = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('kt-auth-react-v')!)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token.api_token,
        },
      }

      const query = [`approved=${status}`, `id=${id}`].filter(Boolean).join('&')

      const res = await axios.get(`${URL.API_BASE_URL}${URL.getReports}?${query}`, config)
      console.log(res?.data?.data?.data[0])

      if (res?.data?.data?.data) {
        setReportData(res?.data?.data?.data[0])
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getReports()
  }, [])

  return (
    <div>
      <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
          View Detail Trip
        </h1>

        <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
          <li className='breadcrumb-item text-muted'>
            <Link to={'/'} className='text-muted text-hover-primary'>
              Home
            </Link>
          </li>

          <li className='breadcrumb-item'>
            <span className='bullet bg-gray-400 w-5px h-2px'></span>
          </li>

          <li className='breadcrumb-item text-muted'>View Detail Trip</li>
        </ul>
      </div>
      <div className='mt-5 rounded p-6 card card-flush '>
        <div className='mt-9'>
          <table className='table' style={{fontSize: '12px', fontWeight: '300'}}>
            <tbody>
              <tr>
                <th>Trip ID / Trip Auto ID</th>
                <td>{reportData?.id}</td>
              </tr>
              <tr>
                <th>Customer Name</th>
                <td>{reportData?.user?.name}</td>
              </tr>
              <tr>
                <th>Customer Mobile Number</th>
                <td>{reportData?.user?.mobile}</td>
              </tr>
              <tr>
                <th>Source Address</th>
                <td>{reportData?.source_hydrant_center?.address}</td>
              </tr>
              <tr>
                <th>Destination Address</th>
                <td>{reportData?.destination?.address}</td>
              </tr>
              <tr>
                <th>Trip Accepted By</th>
                <td>{reportData?.approvedby}</td>
              </tr>
              <tr>
                <th>Trip Accepted At</th>
                <td>{reportData?.created_at}</td>
              </tr>
              <tr>
                <th>Trip Start</th>
                <td>{reportData?.updated_at}</td>
              </tr>
              <tr>
                <th>Trip End</th>
                <td>2024-06-08 10:30:33</td>
              </tr>
              <tr>
                <th>Vehicle</th>
                <td>{reportData?.vehicle?.registration_number}</td>
              </tr>
              <tr>
                <th>Tracking</th>
                <td>
                  <button className='btn btn-primary'>Tracking</button>
                </td>
              </tr>
              <tr>
                <th>Trip Start Image</th>
                <td>
                  <img src={`${URL.API_BASE_URL}${reportData?.destination_image}`} alt='tripimg' />
                </td>
              </tr>
              <tr>
                <th>Before Water Supply</th>
                <td></td>
              </tr>
              <tr>
                <th>After Water Supply</th>
                <td>
                  <a
                    href={`${URL.API_BASE_URL}${reportData?.destination_video}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    -View Video
                  </a>
                </td>
              </tr>
              <tr>
                <th>Remark</th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ViewDetails
