import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  VEHICLE_LIST_VENDOR,
  GET_PERMISSION_LISTS,
  GET_ROLES_LISTS,
  GET_RATE_LISTS,
  GET_CIRCLE_LISTS,
  GET_USERS_LISTS,
  GET_USERS_DETAILS,
  GET_ALL_CRICLE,
  PROFILE_LIST,
  VEHICLE_LIST,
  VEHICLE_DETAILS,
  CERCLE_LIST,
  STATION_LIST,
  STATION_DETAILS,
  DESTINATION_DETAILS,
  ASSIGN_DRIVER,
  OTHER_LINK,
  SETTING,
  GET_ALL_BOOKING,
  GET_USER_FOR_LOGIN,
  ROLES_LIST,
  GET_ALL_PROFILE_ROLL,
  GET_DASHBOARD
} from "../common/constant";
import { ids } from "webpack";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};

const getperListAction = (data: any) => {
  return { type: GET_PERMISSION_LISTS, payload: data };
};
const getrateListAction = (data: any) => {
  return { type: GET_RATE_LISTS, payload: data };
};
const getroleListAction = (data: any) => {
  return { type: GET_ROLES_LISTS, payload: data };
};

const getRoleListActionUser = (data: any) => {
  return { type: ROLES_LIST, payload: data };
};

const getcircleListAction = (data: any) => {
  return { type: GET_CIRCLE_LISTS, payload: data };
};
const getusersListAction = (data: any) => {
  return { type: GET_USERS_LISTS, payload: data };
};
const getusersprofileroll = (data: any) => {
  return { type: GET_ALL_PROFILE_ROLL, payload: data }
}
const getusersDetailsAction = (data: any) => {
  return { type: GET_USERS_DETAILS, payload: data };
};
const getallcricleAction = (data: any) => {
  return { type: GET_ALL_CRICLE, payload: data };
};
const getProfileListAction = (data: any) => {
  return { type: PROFILE_LIST, payload: data };
};

const getVehivleListAction = (data: any) => {
  return { type: VEHICLE_LIST, payload: data };
};
const getVehivleDetailsAction = (data: any) => {
  return { type: VEHICLE_DETAILS, payload: data };
};
const getCrealeLsitAction = (data: any) => {
  return { type: CERCLE_LIST, payload: data };
};
const getStationLsitAction = (data: any) => {
  return { type: STATION_LIST, payload: data };
};
const getStationDetailsAction = (data: any) => {
  return { type: STATION_DETAILS, payload: data };
};
const destinationDetailsAction = (data: any) => {
  return { type: DESTINATION_DETAILS, payload: data };
};
const assignDriverAction = (data: any) => {
  return { type: ASSIGN_DRIVER, payload: data };
};
const otherLinkAction = (data: any) => {
  return { type: OTHER_LINK, payload: data };
};
const settingAction = (data: any) => {
  return { type: SETTING, payload: data };
};

const getVehivlevendorListAction = (data: any) => {
  return { type: VEHICLE_LIST_VENDOR, payload: data };
};
const getuserforloginAction = (data: any) => {
  return { type: GET_USER_FOR_LOGIN, payload: data };
};

export const getAllbookingsAction = (data: any) => {
  return { type: GET_ALL_BOOKING, payload: data };
};

export const getdashboardAction = (data: any) => {
  return { type: GET_DASHBOARD, payload: data };
}


export const getAllBookingsuserview = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");


      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };


      console.log(JSON.parse(token));

      const allDetails = JSON.parse(token)

      const pro = allDetails?.data?.prefix
      const proId = allDetails?.data?.id

      const witch = pro == "jens" ? "&jen_id=" + proId : "&aen_id=" + proId

      const prfix = `/api/${JSON.parse(token)?.data?.prefix}`
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllBookings}?page=${data?.page}&user_id=${data?.user_id}`, config);
      // dispatch(getAllbookingsAction(response?.data?.data));
      dispatch(loading(false));

      return response?.data
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data
    }
  };
};



export const getrolesList = (data: any) => {
  return async (dispatch: any) => {
    try {
      // const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log("typeof token");
      // console.log(JSON.parse(token));

      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + JSON.parse(token).api_token,
      //   },
      // };
      dispatch(loading(true));

      // console.log("typeof token");
      // const  prfix  = `/api/${JSON.parse(token)?.data?.prefix}`

      const response: any = await api.get(`${URL.roles}`);
      // console.log("typeof token");

      // console.log(response?.data?.data);

      dispatch(getRoleListActionUser(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const getUsersForLogin = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("circle_x-login-details");


      // console.log(typeof token);
      // console.log(JSON.parse(token));

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      const prfix = `/api/${JSON.parse(token)?.data?.prefix}`
      const role_id = `${JSON.parse(token)?.data?.circle_id}`


      dispatch(loading(true));
      const response: any = await api.get(`${prfix + URL.getUsersForLogin}?page=${data?.page}&circle_id=${role_id}${data?.search ? `&search=${data?.search}` : ''}`, config);
      dispatch(getuserforloginAction(response?.data?.data));
      dispatch(loading(false));

    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const dashboard = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.get(`${URL.dashboard}`, config);
      console.log("response", response?.data?.data);

      dispatch(getdashboardAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createuserByJen = (data: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.user}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        navigate('/trip-book/' + response?.data?.data?.id)

        // const responseget:any = await api.get(`${URL.user}`,config );
        // const responseget:any = await api.get(`${prfix+URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`,config );
        // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const create_booking = (data: any, navigete: any) => {
  return async (dispatch: any) => {
    try {

      dispatch(loading(true));
      const response: any = await api.post(`${URL.create_booking}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        navigete("/dashboard")
        // const responseget:any = await api.get(`${prfix+URL.get_user_destination}?page=${pageNo}`,config );
        // dispatch(getroleListAction(response?.data?.data));
        // dispatch(destinationDetailsAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const repeatBooking = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const prfix = `/api/${JSON.parse(token)?.data?.prefix}`
      const response: any = await api.post(`${URL.repeatBooking}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        // navigete("/dashboard")
        // const responseget:any = await api.get(`${prfix+URL.get_user_destination}?page=${pageNo}`,config );
        // dispatch(getroleListAction(response?.data?.data));
        // dispatch(destinationDetailsAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
      return response?.data
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data
    }
  };
};

export const getAllBookings = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };


      console.log(JSON.parse(token));

      const allDetails = JSON.parse(token)

      const pro = allDetails?.data?.prefix
      const proId = allDetails?.data?.id

      const witch = pro == "jens" ? "&jen_id=" + proId : "&aen_id=" + proId

      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllBookings}?page=${data + witch}`, config);
      dispatch(getAllbookingsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCompleteBookings = ({ page, id }: { page: any, id: any }) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCompleteBookings}?page=${page}&jen_id=${id}`, config);
      dispatch(getAllbookingsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCompleteBookingsAll = ({ page, id }: { page: any, id: any }) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCompleteBookings}?page=${page}`, config);
      dispatch(getAllbookingsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCancelBookings = ({ page, id }: { page: any, id: any }) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };



      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCancelBookings}?page=${page}&jen_id=${id}`, config);
      dispatch(getAllbookingsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCancelBookingsall = ({ page, id }: { page: any, id: any }) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };



      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCancelBookings}?page=${page}`, config);
      dispatch(getAllbookingsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createAppSettings = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.createAppSettings}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.getAllOtherLinks}?page=1`,config );
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(settingAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateAppSettings = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.updateAppSettings}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.getAllOtherLinks}?page=1`,config );
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(settingAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getAppSettings = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAppSetings}`,);

      if (response?.data?.status == true) {
        // toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.getAllOtherLinks}?page=1`,config );
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(settingAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createOtherService = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.createOtherService}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        if (data2?.page) {
          const response: any = await api.get(`${URL.getAllOtherLinks}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          dispatch(otherLinkAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCurrentBookings = ({ page, id }: { page: any, id: any }) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");
      const parsedToken = JSON.parse(token);

      const config = {
        headers: {
          Authorization: "Bearer " + parsedToken.api_token,
        },
      };



      dispatch(loading(true));

      const response: any = await api.get(`${URL.getCurrentBookings}?page=${page}&jen_id=${id}`, config);
      // console.log(response?.data?.data?.data);

      dispatch(getAllbookingsAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCurrentBookings2 = ({ page}: { page: any }) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");
      const parsedToken = JSON.parse(token);

      const config = {
        headers: {
          Authorization: "Bearer " + parsedToken.api_token,
        },
      };



      dispatch(loading(true));

      const response: any = await api.get(`${URL.getCurrentBookings}?page=${page}`, config);
      // console.log(response?.data?.data?.data);

      dispatch(getAllbookingsAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const updateBooking = (data: any, type: any, pageNo: any, id: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.post(`${URL.updateBooking}`, data, config);

      if (response?.data?.status) {
        toast.success(response?.data?.message)
        const allDetails = JSON.parse(token)
if (id && id == undefined) {
  const responset: any = await api.get(`${URL.getCurrentBookings}?page=${pageNo}&jen_id=${id}`, config);
  dispatch(getAllbookingsAction(responset?.data?.data));
}else{
  const responset: any = await api.get(`${URL.getCurrentBookings}?page=${pageNo}`, config);
  dispatch(getAllbookingsAction(responset?.data?.data));
}
        
        // console.log(response?.data?.data?.data);


      } else {
        toast.error(response?.data?.message)
      }



      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateOtherService = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.updateOtherService}`, data, config);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const response: any = await api.get(`${URL.getAllOtherLinks}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          dispatch(otherLinkAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getAllOtherLinks = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllOtherLinks}?page=${data.page}${data.search ? "&search=" + data.search : ""}`, config);
      dispatch(otherLinkAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const deleteOtherService = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.deleteOtherService}?id=${data?.id}`,);
      // id:productID, page:pageNo
      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const response: any = await api.get(`${URL.getAllOtherLinks}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          dispatch(otherLinkAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const circleList = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.circleList}`, config);
      dispatch(getCrealeLsitAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const vehicleDetail = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.vehicleDetail}`, data);

      if (response?.data?.status == true) {
        // toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.all_vehicles}?page=${pageNo}`,config );
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getVehivleDetailsAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const add_vehicle_doc = (data: any, pageNo: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.add_vehicle_doc}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.all_vehicles}?page=${pageNo}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getVehivleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const update_vehicle_doc = (data: any, pageNo: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_vehicle_doc}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.all_vehicles}?page=${pageNo}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getVehivleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const vehicle_delete = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.vehicle_delete}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.all_vehicles}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // dispatch(getroleListAction(response?.data?.data));
          dispatch(getVehivleListAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const fillingStationDetails = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.fillingStationDetails}`, data);

      if (response?.data?.status == true) {
        // toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.all_vehicles}?page=${pageNo}`,config );
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getStationDetailsAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const delete_filling_station = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.delete_filling_station}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.all_filling_stations}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // dispatch(getroleListAction(response?.data?.data));
          dispatch(getStationLsitAction(responseget?.data?.data));

        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const add_station = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.add_station}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        if (data2?.page) {
          const responseget: any = await api.get(`${URL.all_filling_stations}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // dispatch(getroleListAction(response?.data?.data));
          dispatch(getStationLsitAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const user_destination = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.user_destination}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        if (data2?.page) {
          const response: any = await api.get(`${URL.get_user_destination}?page=${data2.page}${data2.search ? "&search=" + data2.search : ''}`, config);
          dispatch(destinationDetailsAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const delete_user_destination = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.delete_user_destination}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)


        if (data2?.page) {

          const response: any = await api.get(`${URL.get_user_destination}?page=${data2.page}${data2.search ? "&search=" + data2.search : ''}`, config);
          dispatch(destinationDetailsAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const get_user_destination = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.get_user_destination}?page=${data.page}${data.search ? "&search=" + data.search : ''}`, config);
      dispatch(destinationDetailsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const all_filling_stations = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.all_filling_stations}?page=${data.page}${data.search ? "&search=" + data.search : ''}`, config);
      dispatch(getStationLsitAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const assign_manager_station = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.assign_manager_station}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.all_filling_stations}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // dispatch(getroleListAction(response?.data?.data));
          dispatch(getStationLsitAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};







export const create_vehicle = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.create_vehicle}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

        if (data2?.page) {
          const responseget: any = await api.get(`${URL.all_vehicles}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // dispatch(getroleListAction(response?.data?.data));
          dispatch(getVehivleListAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
// export const assign_vehicle_vendor = (data: any, pageNo: any) => {
//   return async (dispatch: any) => {
//     try {
//       const token: any = await localStorage.getItem("kt-auth-react-v");




//       const config = {
//         headers: {
//           Authorization: "Bearer " + JSON.parse(token).api_token,
//         },
//       };
//       dispatch(loading(true));
//       const response: any = await api.post(`${URL.assign_vehicle_vendor}`, data);

//       if (response?.data?.status == true) {
//         toast.success(response?.data?.message)
//         const responseget: any = await api.get(`${URL.all_vehicles}?page=${pageNo}`, config);
//         // dispatch(getroleListAction(response?.data?.data));
//         dispatch(getVehivleListAction(responseget?.data?.data));
//       } else {
//         toast.error(response?.data?.message)
//       }


//       dispatch(loading(false));
//     }
//     catch (error: any) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };

// assignVehicle


export const assign_vehicle_vendor = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.assign_vehicle_vendor}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.all_vehicles}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // dispatch(getroleListAction(response?.data?.data));
          dispatch(getVehivleListAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const all_vehicle_vendor = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };


      dispatch(loading(true));
      const response: any = await api.get(`${URL.all_vehicle_vendor}?page=${data.page}${data.search ? `&search=${data.search}` : ""}`, config);
      dispatch(getVehivlevendorListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const all_vehicles = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.all_vehicles}?page=${data.page}${data.search ? `&search=${data.search}` : ""}`, config);
      dispatch(getVehivleListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const all_vehicles_search = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.all_vehicles}?page=${data?.page}&search=${data?.search}`, config);
      dispatch(getVehivleListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getPermissionsList = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getPermissionsList}`, config);
      dispatch(getperListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getrate = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.rate}?page=${data.page}${data?.search ? "&search=" + data?.search : ''}`, config);
      dispatch(getrateListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createcircle = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.circle}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.circle}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getgetAllCircles = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllCircles}`, config);
      dispatch(getallcricleAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getcircle = (page: any, search: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.circle}?page=${page}${search && `&search=${search}`}`, config);
      dispatch(getcircleListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserdetails = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");


      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.user}/${data}`, config);
      dispatch(getusersDetailsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createuser = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.user}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
          // // dispatch(getroleListAction(response?.data?.data));
          dispatch(getusersListAction(responseget?.data?.data));
        }

      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createuserAndDriver = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.user}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.user}`,config );
        const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const assign_driver = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.assign_driver}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.user}`,config );
        const responseget: any = await api.get(`${URL.all_vehicle_driver}?page=${data2?.page}${data2?.search && `&search=${data2?.search}`}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(assignDriverAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const all_vehicle_driver = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.all_vehicle_driver}?page=${data?.page}${data?.search && `&search=${data?.search}`}`, config);
      dispatch(assignDriverAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const deleteAssignDriver = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.deleteAssignDriver}`, { id: data });

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.all_vehicle_driver}?page=${data2?.page}${data2?.role_id && `&role_id=${data2?.role_id}`}${data2?.search && `&search=${data2?.search}`}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(assignDriverAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const delete_vehicles_vendor = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.delete_vehicles_vendor}`, { id: data });

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const response: any = await api.get(`${URL.all_vehicle_vendor}?page=${data2.page}${data2.search ? `&search=${data2.search}` : ""}`, config);
          dispatch(getVehivlevendorListAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const all_vehicle_driver_Filter = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.all_vehicle_driver}?page=${data?.page}&circle_id=${data?.role_id}`, config);
      dispatch(assignDriverAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const documents = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));


      const response: any = await api.post(`${URL.documents}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.user}`,config );
        // const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const documents_update = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.documents_update}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.user}`,config );
        const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const documentsApprove = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.documentsApprove}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.user}`,config );
        const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const activeInactive = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.activeInactive}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget:any = await api.get(`${URL.user}`,config );
        const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


// export const getuser = (data:any) => {
//   return async (dispatch:any) => {
//     try {
//       const token:any = await localStorage.getItem("kt-auth-react-v");



//       const config = {
//         headers: {
//           Authorization: "Bearer " + JSON.parse(token).api_token,
//         },
//       };
//       dispatch(loading(true));
//       const response:any = await api.get(`${URL.user}?page=${data}`,config );
//           dispatch(getusersListAction(response?.data?.data));
//           dispatch(loading(false));
//     }
//     catch(error:any) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };
export const activeInactive2 = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.activeInactive}`, data, config);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const deleteAssignDrivers = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      // const response:any = await api.post(`${URL.user}`,data );
      const response: any = await api.delete(`${URL.deleteAssignDriver}/${data?.id}`,);
      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.user}?page=${data2?.page}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getusersListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteuser = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      // const response:any = await api.post(`${URL.user}`,data );
      const response: any = await api.delete(`${URL.user}/${data?.id}`,);
      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
          // // dispatch(getroleListAction(response?.data?.data));
          dispatch(getusersListAction(responseget?.data?.data));
        }


      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const deleteProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      // const response:any = await api.post(`${URL.user}`,data );
      const response: any = await api.post(`${URL.deleteProfile}`, { id: data?.id }, config);
      if (response?.data?.status == true) {
        toast.success(response?.data?.message)

      } else {
        toast.error(response?.data?.message)
      }
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getuser = (page: any, search: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.user}?page=${page}${search ? `&search=${search}` : ''}`, config);
      dispatch(getusersListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getdepartmentuser = (page: any, search: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.department}?page=${page}${search ? `&search=${search}` : ''}`, config);
      dispatch(getusersListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.createProfile}`, data, config);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget: any = await api.get(`${URL.getAllProfile}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getProfileListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const assignUserProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.assignUserProfile}`, data, config);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.getAllProfile}?page=1`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getProfileListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAllProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllProfile}?page=${data.page}${data?.search ? `&search=${data?.search}` : ''}`, config);
      dispatch(getProfileListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
// getusersprofilerollwise
export const getusersprofilerollwise = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getusersprofilerollwise}?page=${data?.page}&role_id=${data?.role_id}${data?.search ? `&search=${data?.search}` : ''}`, config);
      dispatch(getusersprofileroll(response?.data?.data));
      dispatch(loading(false));

      console.log(response?.data?.data);

    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const getuserRoleWise = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.userRoleWise}?page=${data?.page}&role_id=${data?.role_id}${data?.search ? `&search=${data?.search}` : ''}`, config);
      dispatch(getusersListAction(response?.data?.data));
      dispatch(loading(false));

      console.log(response?.data?.data);

    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deletecircle = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.delete(`${URL.circle}/${data?.id}`,);
      // id:productID, page:pageNo
      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updatecircle = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };




      // name: e.name,
      // city: e.city,
      // pincode: e.pincode,

      // id,type,status
      dispatch(loading(true));
      const response: any = await api.put(`${URL.circle}/${data?.cate_id}?name=${data?.name}&city=${data?.city}&pincode=${data?.pincode}`,);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const updateUser = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      // name: e.name,
      // city: e.city,
      // pincode: e.pincode,

      // id,type,status
      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_user}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.userRoleWise}?page=${data2?.page}&role_id=${data2?.role_id}`, config);
          // // dispatch(getroleListAction(response?.data?.data));
          dispatch(getusersListAction(responseget?.data?.data));
        }

      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const updateProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      // name: e.name,
      // city: e.city,
      // pincode: e.pincode,

      // id,type,status
      dispatch(loading(true));
      const response: any = await api.post(`${URL.updateProfile}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getuser(data?.page, ''))
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
// update_user_destination

export const update_user_destination = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      // name: e.name,
      // city: e.city,
      // pincode: e.pincode,

      // id,type,status
      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_user_destination}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getcircleListAction(responseget?.data?.data));
        if (data2?.page) {
          const response: any = await api.get(`${URL.get_user_destination}?page=${data.page}${data.search ? "&search=" + data.search : ''}`, config);
          dispatch(destinationDetailsAction(response?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const updateFillingStation = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_filling_stations}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const update_vehicle_driver = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_vehicle_driver}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const update_vehicles_vendor = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_vehicles_vendor}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const update_vehicle = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");

      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      dispatch(loading(true));
      const response: any = await api.post(`${URL.update_vehicle}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        // const responseget: any = await api.get(`${URL.circle}?page=${data?.page}`, config);
        // // dispatch(getroleListAction(response?.data?.data));
        // dispatch(getcircleListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createrate = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.rate}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.rate}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // // dispatch(getroleListAction(response?.data?.data));
          dispatch(getrateListAction(responseget?.data?.data));
        }

      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteRaye = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.delete(`${URL.rate}/${data}`,);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.rate}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // // dispatch(getroleListAction(response?.data?.data));
          dispatch(getrateListAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const updaterate = (data: any, data2: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");




      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };




      // id,type,status
      dispatch(loading(true));
      const response: any = await api.put(`${URL.rate}/${data?.id}?name=${data?.name}&price=${data?.price}&circle_id=${data?.circle_id}&kmFrom=${data?.kmFrom}&kmTo=${data?.kmTo}&effectiveDate=${data?.effectiveDate}&price=${data?.price}&remark=${data?.remark}`,);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        if (data2?.page) {
          const responseget: any = await api.get(`${URL.rate}?page=${data2.page}${data2.search ? "&search=" + data2.search : ""}`, config);
          // // dispatch(getroleListAction(response?.data?.data));
          dispatch(getrateListAction(responseget?.data?.data));
        }
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getRoleList = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");


      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getRoleList}`);
      dispatch(getroleListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createRoles = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.createRoles}`, data);
      dispatch(getroleListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createPermission = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.post(`${URL.getPermissionsList}`, data);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.getPermissionsList}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getperListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletePermission = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response: any = await api.delete(`${URL.getPermissionsList}/${data}`,);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.getPermissionsList}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getperListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatePermission = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token: any = await localStorage.getItem("kt-auth-react-v");



      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };

      // id,type,status
      dispatch(loading(true));
      const response: any = await api.put(`${URL.getPermissionsList}/${data?.id}?type=${data?.type}&status=${data?.status}`,);

      if (response?.data?.status == true) {
        toast.success(response?.data?.message)
        const responseget: any = await api.get(`${URL.getPermissionsList}`, config);
        // dispatch(getroleListAction(response?.data?.data));
        dispatch(getperListAction(responseget?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }


      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};






